import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import reportWebVitals from './reportWebVitals';
import store from 'store';
import RootRouters from './routers';
import { theme } from './styles/MuiTheme';
import '@mui/styles';
import './index.css';

import { LicenseInfo } from '@mui/x-license-pro';
import ScrollToTop from 'modules/common/components/ScrollToTop';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENCE_KEY!);

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ScrollToTop />
          <CssBaseline />
          <RootRouters />
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  </Provider>,
);

reportWebVitals();
